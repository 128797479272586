<template>
    <div v-if="value" class="box-modal">
      <div class="box-modal--content">       
        <div  class="box-modal--area">
        
            <div
        :class="{ 'box-modal--area__border': product }"
        class="box-modal--area"
      >
        <span class="box-modal--close" @click="$emit('input', false)">
          <img :src="close" alt="Fechar box-modal" />
        </span>
        <Paragraph size="40" weight="bold" class="box-modal--subtitle">PARCEIROS</Paragraph>
        
      </div>


            <div class="box " id="partners">
          <div class="box--content">
            <!-- <Heading size="1" weight="x-bold">{{
              $t('home.menu.partners')
            }}</Heading> -->

           

           
        <div>
          <table style="width: 100%;">
            <tr>
              <td style="text-align: center; padding-left: 25px;"><img  :src="escavaparts" style="width: 130px; height: 100px;"/> </td>
              <td style="text-align: center;"><img  :src="micromazza" style="width: 200px; height: 100px; margin-left: 10%"/></td>
              <td style="text-align: center;"><img  :src="rda" style="width: 130; height: 100px; margin-left: 10%"/></td>
            </tr>
            <tr>
              <td style="text-align: center; padding-top: 53px;"><img  :src="termac" style="width: 130; height: 110px; margin-left: 10%"/></td>
              <td style="text-align: center; padding-top: 53px;"><img  :src="engtech" style="width: 130; height: 110px; margin-left: 10%"/></td>
              <td style="text-align: center; padding-top: 53px;"><img  :src="jmf" style="width: 130; height: 110px; margin-left: 10%"/></td>
            </tr>
            <tr style="margin-top: 55px;">
              <td style="text-align: center; padding-top: 53px;"><img  :src="pim" style="width: 130; height: 110px; margin-left: 10%"/></td>
              <td style="text-align: center; padding-top: 53px;"><img  :src="metacon" style="width: 130; height: 110px; margin-left: 10%"/></td>
              <td style="text-align: center; padding-top: 53px;"><img  :src="patinsrye" style="width: 130; height: 110px; margin-left: 10%"/></td>
            </tr>

            <tr style="margin-top: 55px;">
              <td style="text-align: center; padding-top: 53px;"></td>
              <td style="text-align: center; padding-top: 53px;"><img  :src="vieceli" style="width: 130; height: 110px; margin-left: 10%"/></td>
              <td style="text-align: center; padding-top: 53px;"></td>
            </tr>
          </table>          
          </div>          
          </div>
        </div>

        </div>
      </div>
      <!-- OVERLAY -->
      <div class="box-modal--overlay" @click="$emit('input', false)"></div>
    </div>
  </template>
  
  <script>
  
  import close from '@/assets/images/close.svg'
  import escavaparts from '@/assets/images/partners/escava_parts.jpg'
 import micromazza from '@/assets/images/partners/micromazza.jpg'
 import rda from '@/assets/images/partners/rda.png'
 import termac from '@/assets/images/partners/termac.jpg'
 import engtech from '@/assets/images/partners/ENGTECH.png'
 import jmf from '@/assets/images/partners/JMF.png'
  import pim from '@/assets/images/partners/PIM.png'
    import metacon from '@/assets/images/partners/METACON.png'
      import patinsrye from '@/assets/images/partners/patinsrye.png'
       import vieceli from '@/assets/images/partners/vieceli.png'
  /** Componente padrão de modais */
  export default {
    name: 'Modal',
    components: {            
    }, 
    props:{
        value: {
      type: Boolean,
      default: false
    }
    },
    data() {
      return {
        escavaparts: escavaparts,
      micromazza: micromazza,
      rda:rda,
      jmf:jmf,
      patinsrye:patinsrye,
      pim:pim,
      metacon:metacon,
      engtech:engtech,
      vieceli:vieceli,
      termac:termac,
        close: close /** Ícone de fechar */
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .box-modal {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  
    &--content {
      width: 100%;
      max-width: 1300px;
      background: $white;
      margin: 20px;
      border-radius: 4px;
      position: relative;
      max-height: 90%;
      overflow-y: auto;
    }
  
    &--overlay {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      top: 0%;
      left: 0%;
      z-index: -1;
    }
  
    &--area {
      padding: 40px 115px;
  
      @media screen and (max-width: 768px) {
        padding: 20px 20px;
      }
  
      &__border {
        border-bottom: 1px solid $tertiary;
      }
  
      &__product {
        display: flex;
        align-items: flex-start;
        gap: 40px;
  
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  
    &--banner {
      height: 290px;
      overflow: hidden;
      position: relative;
  
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
        max-width: 150%;
      }
    }
  
    &--close {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid $tertiary;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 25px;
  
      @media screen and (max-width: 768px) {
        margin: 20px;
      }
  
      img {
        width: 8px;
      }
    }
  
    &--subtitle {
      text-transform: uppercase;
      color: #e56000;
      border-top: 2px solid #e56000;
      display: inline-block;
      padding-top: 10px;
    }
  
    &--title {
      line-height: 42px;
      color: $fontColor;
      margin-bottom: 0px;
    }
  
    &--description {
      line-height: 28px;
  
      &__title {
        color: $primary;
      }
  
      &::v-deep {
        p {
          margin-bottom: 10px;
          display: block;
  
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
  
        ul {
          margin-bottom: 10px;
          padding-left: 20px;
          list-style: disc;
        }
      }
    }
  
    &--image {
      width: 100%;
      background: $gray;
      border-radius: 4px;
      padding: 32px;
      max-width: 311px;
  
      @media screen and (max-width: 768px) {
        margin: 0px auto;
      }
  
      img {
        width: 100%;
        border-radius: 4px;
      }
    }
  
    &--product {
      width: 100%;
    }
  }
  </style>
  